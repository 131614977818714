import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './theme/theme';
import { MainContainer } from './containers/MainContainer';
import { AppProvider } from './context/AppContext';


function App() {
  return (
    <>
      {/* <GlobalStyle /> */}
      <ThemeProvider theme={ theme }>
        <AppProvider>
          <MainContainer />
        </AppProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
