import styled from "styled-components";
import { Container3D } from "./Contianer3D";
import { ContainerUI } from "./ContainerUI";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { useTransition } from "@react-spring/web";

export const MainContainer = ()=>{

    // const { state } = useContext(AppContext);
    // const [ isLoaded, setisLoaded ] = useState([1]);

    // const coverTransition = useTransition(
    //     isLoaded,
    //     {
    //         from: {
    //             opacity: 1,
    //             left: "0%"
    //         },
    //         enter: {
    //             opacity: 1,
    //             left: "0%"
    //         },
    //         leave:{
    //             opacity: 0,
    //             left: "-100%"
    //         },
    //         config: {
    //             duration: 450
    //         }
    //     }
    // );

    // useEffect(()=>{
    //     if(state.activeVR){
    //         setisLoaded([]);
    //     }
    // }, [state.activeVR]);

    return(
        <MainWrapper>
            <Container3D />
            <ContainerUI />
            {/* {
                coverTransition((styles, _)=>{
                    return <ContainerUI styles={ styles } />
                })
            } */}
        </MainWrapper>
    );
};

const MainWrapper = styled.div`
    width: 100dvw;
    height: 100dvh;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
`;