import styled from "styled-components";
import { XRButton } from "@react-three/xr";
import { useProgress } from "@react-three/drei";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useTransition, animated as a } from '@react-spring/web';

export const ContainerUI = ()=>{

    const { state, setState } = useContext(AppContext);
    const { progress }= useProgress();
    const [ isOpen, setisOpen ] = useState(false);
    const [ isLoaded, setisLoaded ] = useState(false);
    const [ item, setItem ] = useState([1]);

    const coverTransition = useTransition(item, {
        from: {
            opacity: 0
        },
        enter: {
            opacity: 1
        },
        leave:{
            opacity: 0
        }
    })

    const openCover = ()=>{
        setState(state.setActive(false));
    }
    
    const closeCover = ()=>{
        setState(state.setActive(true));
    }

    useEffect(()=>{
        // console.log("effect...", isLoaded && isOpen);
        if(state.loaded && state.active){
            setItem([]);
        }
        else{
            setItem([1]);
        }
    }, [state.loaded, state.active, setItem]);

    useEffect(()=>{
        if(progress === 100){
            // setisLoaded(true);
            setState(state.setLoaded(true));
        }
    }, [progress]);

    return(
        // <Cover style={ styles }>
        //     <ContentContainer>
        //         <LogoContainer>
        //             <img src="./img/hyatt_logo.png" alt="hyatt resindences"/>
        //         </LogoContainer>
        //         <ActionContainer>
        //             <ButtonContainer>
        //                 <ButtonWrapper>
        //                     {
        //                         isLoaded
        //                         ?(
        //                             <StyledXR
        //                                 mode={"VR"} onClick={ closeCover }
        //                             />
        //                         )
        //                         :(      <LoadingLabelContainer>
        //                                     <LoadingLabel>
        //                                         { progress.toFixed(0) }%
        //                                     </LoadingLabel>
        //                                 </LoadingLabelContainer>
        //                         )
        //                     }
        //                 </ButtonWrapper>
        //             </ButtonContainer>
        //         </ActionContainer>
        //     </ContentContainer>
        // </Cover>
        <>
            {

                coverTransition((styles, _)=>{
                    return(
                        <>
                            <CoverBlack style={ styles } onClick={ closeCover }>
                                <ContentContainer>
                                    {
                                        state.loaded
                                        ?(
                                            <LoaderContainer>
                                                <SvgStyled version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 150 150">
                                                    <g id="hand">
                                                        <path d="M92.4,114.5l-1.5-1.8c-0.5-0.7-1.4-1.2-2.3-1.2c0.2-0.9,0-1.8-0.6-2.5l-1.8-1.8c-1-1-2.4-1.3-3.7-0.9l-0.2,0.1 c0.6-1.6,0-3.4-1.4-4.4l-1.5-1c-1.4-1-3.3-0.9-4.6,0.2l4.8-15.4c0.4-1.2,0.3-2.6-0.3-3.7c-0.6-1.2-1.6-2-2.9-2.4 c-1.1-0.3-2.2-0.2-3.2,0.3c-1,0.5-1.7,1.4-2.1,2.5l-5.9,19.1l-2.7,0.6c-1.5,0.3-2.7,1.3-3.4,2.6L53.9,115c-1.2,2.3-1.5,4.9-0.9,7.5 l1.8,7.6c0.1,0.4,0.4,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9l-1.8-7.6c-0.5-2.2-0.2-4.4,0.8-6.4l5.3-10.1 c0.5-0.9,1.4-1.6,2.4-1.9l1.9-0.4l-3,9.5c-0.1,0.4-0.4,0.8-0.7,1.1l-2,2c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.2,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.5-0.2l2-2c0.5-0.5,0.9-1.1,1.1-1.8L72.7,83c0.2-0.7,0.7-1.2,1.3-1.6c0.6-0.3,1.3-0.4,2-0.2c0.9,0.3,1.5,0.8,2,1.6 c0.4,0.8,0.5,1.7,0.2,2.5l-6.6,21c-0.1,0.3,0,0.6,0.3,0.8c0.2,0.2,0.6,0.2,0.9,0.1l1.1-0.6c0.4-0.2,0.5-0.7,0.3-1.1 c-0.1-0.2-0.3-0.3-0.5-0.4l0.3-0.8l1.6-1.6c0,0,0,0,0.1-0.1c0.7-1,2.1-1.1,3.1-0.4l1.5,1c0.9,0.6,1.2,1.7,0.8,2.7l-1.3,3.2 c-0.2,0.4,0,0.8,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5l0.6-1.4l1.5-0.5c0.7-0.3,1.6-0.1,2.1,0.5l1.8,1.8 c0.3,0.3,0.4,0.7,0.2,1.1l-0.3,0.7c-0.1,0.2-0.1,0.5,0,0.7c0.1,0.2,0.4,0.4,0.7,0.4l1.1,0c0.4,0,0.8,0.2,1.1,0.6l1.5,1.8 c0.2,0.3,0.3,0.7,0.2,1.1l-0.6,2.6c-0.1,0.5-0.3,0.9-0.5,1.4l-3.7,8.4c-0.1,0.1-0.1,0.2-0.2,0.3l-0.1,0.2c-0.2,0.4-0.1,0.8,0.3,1.1 c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.4l0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.5l3.7-8.4c0.3-0.5,0.5-1.1,0.7-1.7l0.6-2.6 C93.1,116.1,92.9,115.2,92.4,114.5z" />
                                                    </g>
                                                    <g id="touch">
                                                        <path d="M95.7,77.4c-0.4-5.2-2.9-9.8-6.8-13.2c-4-3.3-9-4.9-14.1-4.5C64.2,60.6,56.2,70,57.1,80.6 c0.4,5.2,2.9,10,7,13.3c0.7,0.5,1.6,0.4,2.2-0.2c0.5-0.7,0.4-1.6-0.2-2.2c-3.4-2.8-5.5-6.8-5.9-11.2c-0.8-8.9,5.9-16.8,14.9-17.6 c4.3-0.4,8.6,1,11.9,3.8c3.3,2.8,5.4,6.7,5.7,11.1c0.7,8.2-4.8,15.6-12.9,17.3c-0.8,0.2-1.4,1-1.2,1.8c0.2,0.7,0.8,1.2,1.5,1.2 c0.1,0,0.2,0,0.3,0C90,95.9,96.6,87.1,95.7,77.4z" />
                                                        <path d="M86.5,78.1c0.3,3.6-1.4,7.1-4.4,9.2c-0.4,0.2-0.4,0.7-0.2,1.1c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1 c3.5-2.4,5.4-6.4,5-10.6c-0.5-6.4-6.2-11.1-12.6-10.6c-6.4,0.5-11.1,6.2-10.6,12.6c0.1,1.2,0.4,2.3,0.8,3.4c0.2,0.4,0.6,0.6,1,0.4 c0.4-0.2,0.6-0.6,0.4-1c-0.4-1-0.6-2-0.7-3c-0.5-5.5,3.7-10.4,9.2-10.9C81.1,68.5,86,72.6,86.5,78.1z" />
                                                    </g>
                                                    <g id="arrow_left">
                                                        <polygon points="25.9,59.3 9.5,75.7 9.5,81.9 25.9,98.4 32.2,92.1 21.1,81 39.2,81 39.2,76.6 21.1,76.6 32.2,65.5"/>
                                                    </g>
                                                    <g id="arrow_right">
                                                        <polygon points="124.1,98.4 140.5,81.9 140.5,75.7 124.1,59.3 117.8,65.5 128.9,76.6 110.8,76.6 110.8,81 128.9,81 117.8,92.1" />
                                                    </g>
                                                    <g id="arrow_top">
                                                        <polygon points="93.9,29.2 77.5,12.8 71.3,12.8 54.8,29.2 61.1,35.5 72.2,24.4 72.2,42.5 76.6,42.5 76.6,24.4 87.7,35.5"/>
                                                    </g>
                                                </SvgStyled>
                                            </LoaderContainer>
                                        )
                                        :(
                                            <LoaderContainer>
                                                <LoaderWrapper>
                                                    <LoadFrame>
                                                        <LoadFrameContainer>
                                                            <LoadingLabel>
                                                                { progress.toFixed(0) }
                                                                <span>%</span>
                                                            </LoadingLabel>

                                                        </LoadFrameContainer>
                                                    </LoadFrame>
                                                </LoaderWrapper>
                                            </LoaderContainer>
                                        )
                                        
                                    }

                                </ContentContainer>
                            </CoverBlack>
                        </>
                    );

                })
            }
            { 
                state.active
                ?(
                    <ButtonClose onClick={ openCover }>
                        <CloseIcon />
                    </ButtonClose>
                )
                :(
                    <></>
                )
            }
        </>
    );
};

const CoverBlack = styled(a.div)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #2525257e;
    top: 0;
    left: 0;
`;

const LoaderContainer = styled.div`
    width: 90%;
    max-width: 150px;
`;

const LoaderWrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
`;

const LoadFrame = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LoadFrameContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgStyled = styled.svg`
    user-select: none;
    touch-action: none;
    pointer-events: none;

    path, polygon{
        fill: #ffffff;
    }

    #hand{
        animation: hand-move 1s alternate infinite;
    }
    
    #touch{
        animation: touch-blink 1s alternate infinite;
    }

    @keyframes hand-move{
        0%{
            transform: translateX(-10%);
        }
        100%{
            transform: translateX(10%);
        }
    }

    @keyframes touch-blink{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
`;

const Cover = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #f9faf4;
    top: 0;
    left: 0;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const LogoContainer = styled.div`
    width: 90%;
    max-width: 800px;
    margin-bottom: 50px;

    img{
        width: 100%;
        object-fit: cover;
    }
`;

const ActionContainer = styled.div`
    width: 90%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingLabelContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF00;
    border-radius: 50%;
    border: 10px solid #a59162;
    color: black;
    font: ${({ theme }) => theme.utils.fluidType(44, 72)};;
    font-weight: light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LoadingLabel = styled.p`
    text-align: center;
    font-family: ${({ theme }) => theme.font.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.utils.fluidType(24,32)};
    line-height: 1.0;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.color.white};

    span{
        font-family: ${({ theme }) => theme.font.primary};
        font-weight: ${({ theme }) => theme.font.weight.light};
        font-size: ${({ theme }) => theme.utils.fluidType(18,21)};
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    max-width: 220px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
`;

const StyledXR = styled(XRButton)`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF00;
    border-radius: 50%;
    border: 10px solid #a59162;
    color: black;
    font-size: ${({ theme }) => theme.utils.fluidType(13, 17)};;
    font-weight: light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(1.0) translate(-50%, -50%);
    transition: transform 0.25s ease-out;

    &:hover{
        transform: scale(1.1) translate(-50%, -50%);
    }
`;

const ButtonClose = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform:  translateX(-50%);
    transform-origin: center;
    transition: background-color 0.25s ease-out;
    cursor: pointer;
    background-color: #ffffff00;

    &:hover{
        background-color: #ffffff1f;
    }

`;

const CloseIcon = styled.span`
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: relative;
    transform-origin: center;

    &::after, &::before{
        display: block;
        content: "";
        width: 70%;
        height: 4px;
        border-radius: 2px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
    }

    &:after{
        transform: translate(-50%, -50%) rotate(45deg);
    }
    
    &:before{
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;