import { Suspense, useContext, useRef } from "react";
import styled from "styled-components";

import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { ACESFilmicToneMapping, SRGBColorSpace, VSMShadowMap } from "three";
import { AppContext } from "../context/AppContext";
import { animated as a, useSpring } from "@react-spring/three";
import { HyattLoader } from "../components/HyattLoader";
import { Controllers, Hands, TeleportationPlane, XR } from "@react-three/xr";
import { GltfLoader } from "../components/GltfLoader";


export function Container3D(){

    const AnimatedOrbitControls = a(OrbitControls);
    
    const { state, setState } = useContext(AppContext);
    const controls = useRef(null);
    const cameraRef = useRef(null);

    const [{ x: tx, y: ty, z: tz }] = useSpring(()=>({
        x: 0,
        y: 4.5,
        z: 0,
        config:{
            duration: 500
        }
    }));

    const openCover = ()=>{
        setState(state.setActive(false));
    }
  
    return(
        <Wrapper3D>
            <Canvas 
                // frameloop="demand" 
                
                gl={ { 
                    antialias: true, 
                    localClippingEnabled: !state.isXray,
                    outputColorSpace: SRGBColorSpace,
                    toneMapping: ACESFilmicToneMapping,
                    toneMappingExposure: 1
                    
                } }
                camera={{ 
                    ref: cameraRef,  
                    position: [-50, 50, 50], 
                    fov: 17.5, 
                    near: 0.01, 
                    far: 200 
                }} 
                dpr={[1,2]}
                shadows="soft"
                tone-mapping={ ACESFilmicToneMapping }
                shadow-map-type={ VSMShadowMap }
                onDoubleClick={ openCover }
            >
                {/* <XR>
                    <Controllers />
                    <Hands />
                    <TeleportationPlane 
                        leftHand={ true }
                        rightHand={ true }
                        maxDistance={ 10 }
                    /> */}

                    <Suspense>
                        <GltfLoader url="./models/scene.gltf" scale={ 0.1 }/>
                    </Suspense>
                    {/* <Suspense>
                        <HyattLoader url="./models/hyatt.glb"/>
                    </Suspense> */}
                    <AnimatedOrbitControls 
                        ref={ controls }
                        enablePan={ false }
                        enableDamping={ true } 
                        dampingFactor={ 0.1 } 
                        minDistance={ 10 } 
                        maxDistance={ 50 } 
                        minPolarAngle={ 0 } 
                        maxPolarAngle={ Math.PI * 0.45 }
                        target-x={ tx }
                        target-y={ ty }
                        target-z={ tz }
                        // onChange={ enviromentListener } 
                        // enabled={ state.controlsEnabled }
                        makeDefault
                        target={[0, 4.5, 0]}
                    />
                    {/* <Actions /> */}
                {/* </XR> */}
                {/* <Environment background={true} files="./img/mondarrain.hdr" /> */}
            </Canvas>
        </Wrapper3D>
    );
};

const Wrapper3D = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;