import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three-stdlib";

export const GltfLoader = ({ url, ...props })=>{
    const obj = useLoader(GLTFLoader, url);

    return(
        <group {...props}>
            <primitive object={ obj.scene }></primitive>
        </group>
    );
}