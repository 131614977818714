export class State{
    constructor(){
        this.loaded = false;
        this.active = false;
        // this.activeVR = false;
    }

    // setActiveVR(value){
    //     this.loaded = value;
    //     return this.recycle();
    // }
    
    setActive(value){
        this.active = value;
        return this.recycle();
    }

    setLoaded(value){
        this.loaded = value;
        return this.recycle();
    }

    recycle(){
        let state = { ...this }
        Object.setPrototypeOf(state, State.prototype);
        return state;
    }
}